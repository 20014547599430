/**
 *
 * Route file
 * Reports Frontend
 * 
*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { history } from './History';
import PW_report from '../Components/Reports/PW_report';
import MileStoneReport from '../Components/Reports/msreport';
import PageNotFound from '../Components/Reports/PageNotFound';
import CollectionPotentialReport from "../Components/Reports/Collection_potential_report";
import InventoryReport from "../Components/Reports/InventoryReport";
import CollectionsReport from "../Components/Reports/Collections_report";
import InventoryAllocationReport from "../Components/Reports/Inventory_Allocation_report";
import InventoryMilestoneReport from "../Components/Reports/Inventory_milestone_report";
import ResourceUtilizationReport from "../Components/UtilizationComponent/ResourceUtilizationReport";
import CsmReport from '../Components/CsmReportComponent/CsmReport';
import PmoMsReport from '../Components/PmoReportComponent/PmoMsReport';
import AcvReport from '../Components/AcvReportComponent/AcvReport';
import MsAllocationReport from '../Components/MsAllocationReportComponent/MsAllocationReport';
import PmsDashboard from '../Components/PMSComponent/PmsDashboard';
import PmsManage from '../Components/PMSComponent/PmsManage';
import PmsView from '../Components/PMSComponent/PmsView';
import PmsReview from '../Components/PMSComponent/PmsReview';
import MenuBar from '../Components/MenuComponent/MenuBar';
import StarRating from '../Components/PMSComponent/StarRating';
import AddQuestion from '../Components/PMSComponent/AddQuestion';
import PmsSettings from '../Components/PMSComponent/PmsSettings';
import Notifications from '../Components/NotificationComponent/Notifications';


class Routes extends Component {
    constructor(props) {
        super(props);
      }
    render() {
        return (
            <Router history={history}>
                <div>
                    <Switch>
                        {/* <Route exact path='/:id' component={ PW_report }></Route> */}
                        <Route exact path='/pw_report' component={PW_report}></Route>
                        <Route exact path='/ms_report' component={MileStoneReport}></Route>
                        <Route exact path='/collections_report' component={CollectionsReport}></Route>
                        <Route exact path='/collections_potential_report' component={CollectionPotentialReport}></Route>
                        <Route exact path='/collections_potential_report/:filter_name/:filter_id' component={CollectionPotentialReport}></Route>
                        <Route exact path='/inventory_report' component={InventoryReport}></Route>
                        <Route exact path='/inventory_allocation_report' component={InventoryAllocationReport}></Route>
                        <Route exact path='/inventory_milestone_report' component={InventoryMilestoneReport}></Route>
                        <Route exact path='/utilization_report' component={ResourceUtilizationReport}></Route>
                        <Route exact path='/csm_report' component={CsmReport}></Route>
                        <Route exact path='/pmo_ms_report' component={PmoMsReport}></Route>
                        <Route exact path='/acv_report' component={AcvReport}></Route>
                        <Route exact path='/ms-allocation-report' component={MsAllocationReport}></Route>
                        <Route exact path='/pms_dashboard' component={PmsDashboard}></Route>
                        <Route exact path='/pms_dashboard/:filter_name/:filter_id' component={PmsDashboard}></Route>
                        <Route exact path='/pms_manage' component={PmsManage}></Route>
                        <Route exact path='/pms_review' component={PmsReview}></Route>
                        <Route exact path='/pms_view' component={PmsView}></Route> 
                        <Route exact path='/star_rating' component={StarRating}></Route>
                        <Route exact path='/add' component={AddQuestion}></Route>
                        <Route exact path='/pms_settings' component={PmsSettings}></Route>
                        <Route exact path='/notifications' component={Notifications}></Route>
                        <Route exact path="/not-found" component={PageNotFound} />
                    </Switch>
                    <MenuBar/>
                </div>
            </Router>
        );
    }

}
export default Routes;
