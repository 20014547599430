import React, { Component } from 'react';
import Swal from "sweetalert2";
import { Row, Button, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import {
    getChapterLead, saveQuestion, getReportFilterPmo, getLoginUser, getLoginUserPermission,
    setAddClass, getLevel, getStartEndDate, getReportFilter
} from "../../Action";
import { batch, connect } from 'react-redux';
import "./pms.css";
import { getBackEndConstant } from "../../Config/Constant";
import DatePicker from "react-datepicker";
import * as constants from "../../Config/Constant";
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
var _ = require("lodash");

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    showCloseButton: true,
    customClass: {
        popup: "colored-toast",
        icon: "white-toast-icon",
    },
    timer: 3000,
    onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});
class AddQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chapterLead: [],
            chapterLeadValue: (this.props.isEditFlag) ? this.props.editQuestion.chapter : '',
            snameArray: [],
            sname: [],
            report_practice: [],
            report_service: [],
            practiceHead: [],
            selectService: this.props.isEditFlag ? [] : this.props.selectedFilter?.filteredLos,
            selectBu: [],
            // selectBuFilter: [],
            selectBuFilter: this.props.isEditFlag ? [] : this.props.selectedFilter?.filteredBU,
            buFilter: this.props.isEditFlag ? [] : this.props.selectedFilter?.filteredBU,
            reportList: [],
            serviceName: [],
            checkedList: [],
            usersService: [],
            question: this.props.isEditFlag ? this.props.editQuestion.question : '',
            questionType: getBackEndConstant().questionType,
            isQuestion: false,
            isOption: false,
            isChapterAll: false,
            selectQuestionOption: '',
            isOptionArray: this.props.isEditFlag ? this.props.editQuestion.is_option : [],
            data: [],
            isAddOption: false,
            inputFields: [''],
            validationErrors: {},
            showError: false,
            businessError: [],
            serviceError: [],
            questionError: [],
            optionError: [],
            addOption: this.props.isEditFlag ? false : true,
            isTypeChange: false,
            questionTypeError: [],
            loginUserData: [],
            loginUserPermission: [],
            filter_start_date: this.props.isEditFlag ? new Date(this.props.editQuestion.start_date) : new Date(),
            filter_end_date: this.props.isEditFlag ? new Date(this.props.editQuestion.end_date) : new Date(),
            isActive: false,
            hideChapter: false,
            editbuDetails: [],
            level: [],
            selectLevel: [],
            fieldArray: '',
            selectedField: [],
            levelError: [],
            mandatory: '',
            isChecked: this.props.isEditFlag ? this.props.editQuestion.mandatory : false,
            isComments: false,
            isRating: this.props.isEditFlag ? this.props.editQuestion.comments_rating : false,
            optionActive: false,
            chapter: '',
            startQuesDate: '',
            endQuesDate: '',//moment().format('DD-MM-YYYY'),
            batch: '',
            isAddMoreQuestion: false,
            existingSelectedBu: "",
            existingSelectedLos: "",
            existingSelectedChapter: "",
            existingSelectedLevel: "",
            isSaved: false,
            isManageQuestionFiltered: this.props.selectedFilter?.isManageQuestionFiltered,
            isBuFiltered: this.props.selectedFilter?.isManageQuestionFiltered,
            selectChapter: this.props.isEditFlag ? [] : this.props.selectedFilter?.filteredChapter,
        };
    }
    /**
     * Intial function call 
     */

    componentDidMount() {
        if (this.state.isManageQuestionFiltered) {
            this.getReportByHead(this.state.selectBuFilter)
        }
        if (!this.props.isEditFlag) {
            this.getUser(this.props.selectedFilter?.filteredLos);
            this.clearFormData();
        } else {
            this.getUser();
        }

        if (this.props.isEditFlag) {
            this.setState({
                isQuestion: true,
                isOption: false,
            })
        }
        if (this.props.isEditFlag && (this.props.editQuestion.question_type == 3)) {
            this.setState({
                isOption: true,
                isQuestion: true
            })
        }
        if (this.props.isAddQuestionModal) {
            const body = document.body;
            body.style.cssText = "overflow: scroll !important; overflow-x: hidden !important";
        }
    }

    ToastAlert(icon, title) {
        return Toast.fire({
            target: document.getElementById("form-modal"),
            icon: `${icon}`,
            title: `${title}`,
        });
    }
    /**
      * Get the login user details
      */
    getUser = (selectedLos) => {
        this.setState({ loading: true });
        this.props.getLoginUser().then((response) => {
            this.props.getLoginUserPermission().then((response) => {
                this.setState({
                    loading: false, loginUserData: this.props.loginDetails.loginDetails,
                    loginUserPermission: this.props.loginDetails.loginUserPermission
                });
                this.getDateaAndBatch();
                this.getChapterLead(selectedLos);
                this.getPracticeService();
                this.getLevel();
                if (this.props.isEditFlag) {
                    this.editData();
                }

            });
        });

    }

    /**
     * edit details
     * @param {*} isAllPractice 
     */
    editData = () => {
        let editbuDetails = [];
        let editServiceDetails = [];
        let editChapterDetails = [];
        let editLevelDetails = [];
        if (this.props.editQuestion) {
            let option = {}; let serviceOption = {}; let chapterOption = {}; let levelOption = {};
            if (this.props.editQuestion.bu_name) {
                option.label = this.props.editQuestion.bu_name;
                option.value = this.props.editQuestion.business_unit;
                editbuDetails.push(option);
                if (this.props.isEditFlag && this.state.loginUserData && (this.state.loginUserData.role_id == 1 || this.state.loginUserData.role_id == 8
                    || this.state.loginUserData.role_id == constants.HR)) {
                    let editBuOption = {
                        label: this.props.editQuestion.bu_name,
                        value: this.props.editQuestion.business_unit,
                        bu_id: parseInt(this.props.editQuestion.buId[0], 10),
                        id: parseInt(this.props.editQuestion.buHead[0], 10),
                        bu: this.props.editQuestion.bu_name,
                    };
                    this.getReportByHead(editBuOption);
                }

            } else {
                option.label = (this.props.editQuestion.business_unit === 'all' || this.props.editQuestion.business_unit === 'All') ? "All" : '';
                option.value = "All";
                editbuDetails.push(option);

            }
            if (this.props.editQuestion.service) {
                let services = this.props.editQuestion.service ? (this.props.editQuestion.service) : [];
                services.forEach((item, index) => {
                    if (item.value === 'all' || item.value === 'All' || item.value === '') {
                        levelOption.id = 'All';
                        levelOption.label = (item.value === 'all' || item.value === 'All') ? "All" : '';
                        levelOption.value = 'All';
                        editServiceDetails.push(levelOption);
                    } else {
                        editServiceDetails.push({
                            label: item.label,
                            id: parseInt(item.id),
                            value: parseInt(item.id),
                            head: 175,
                            bu: parseInt(item.bu),
                            isDisabled: false
                        });
                    }
                });
            }

            if (this.props.editQuestion.chapterName) {
                let data = this.props.editQuestion.chapterName && this.props.editQuestion?.is_chapter_all==0 ? (this.props.editQuestion.chapterName) : [];
                data.forEach((item, index) => {
                    if (item === 'all' || item === 'All') {
                        levelOption.label = 'All';
                        levelOption.value = 'All';
                        editChapterDetails.push(levelOption);
                    } else if (item == '') {
                        levelOption.label = 'All';
                        levelOption.value = 'All';
                        editChapterDetails.push(levelOption);
                        // editChapterDetails = [];
                    } else {
                        editChapterDetails.push({
                            label: item, value: item
                        });
                    }
                });

                if(this.props.editQuestion.is_chapter_all==1){
                    editChapterDetails.push({label: 'All', reporting_to: '', service: this.props.editQuestion.serviceId, value: this.props.editQuestion.chapterName});
                }
            }
            if (this.props.editQuestion.level) {
                let data = this.props.editQuestion.level ? (this.props.editQuestion.level) : [];
                data.forEach((item, index) => {
                    if (item === 'all' || item === 'All') {
                        levelOption.label = (item === 'all' || item === 'All') ? "All" : '';
                        levelOption.value = 'All';
                        editLevelDetails.push(levelOption);
                    } else {
                        editLevelDetails.push({
                            label: item, value: item
                        });
                    }
                });
            }
        }


        this.setState({
            selectBuFilter: editbuDetails,
            selectService: editServiceDetails,
            selectChapter: editChapterDetails,
            selectLevel: editLevelDetails,
        });
    }


    /* get the service filter value */
    getPracticeService = (isAllPractice) => {
        this.setState({
            loading: true,
            isAllPractices: isAllPractice,
            filterService: [],
            report: { service: [] },
            sname: [],
            allService: [],
            serviceName: [],

        });
        let dateValue = new Date();
        let date = new Date(dateValue.getFullYear(), dateValue.getMonth(), 2);
        let start_date = this.state.filter_start_date ? this.state.filter_start_date : date;
        let end_date = this.state.filter_end_date ? this.state.filter_end_date : date;
        let dataObject = {};
        dataObject.start_date = start_date;
        dataObject.end_date = end_date;
        dataObject.filter = isAllPractice;
        let c = 0;
        this.props.getReportFilter(dataObject).then((response) => {
            this.props.pmoFilter.directors.forEach((value) => {
                if (this.state.bu_id == value.bu_id) {
                    c = 1;
                }
            });
            let bu_id = c == 1 ? this.state.bu_id : "";
            let selectBuFilter = c == 1 ? this.state.selectBuFilter : [];
            let sname = [];
            let allService = [], serviceName = [], allChapter = [];
            if (this.state.bu_id != "" && this.state.bu_id != undefined && this.state.bu_id != 0) {
                this.props.pmoFilter.services.forEach((service) => {
                    if (this.state.bu_id == service.bu) {
                        sname.push(service);
                    }
                });
                this.props.pmoFilter.allservices.forEach((service) => {
                    if (this.state.bu_id == service.bu) {
                        allService.push(service);
                    }
                });
            } else {
                sname = this.props.pmoFilter.services;
                allService = this.props.pmoFilter.allservices;
            }
            this.setState({
                loading: false,
                usersService: this.props.pmoFilter.services,
                snameArray: this.props.pmoFilter.services,
                bu_id: bu_id,
                // selectBuFilter: selectBuFilter,
                sname: sname,
                allService: allService
            })
            let allOption = { id: 'All', label: 'All', value: 'all' };
            serviceName.push(allOption);
            this.state.sname.forEach((value) => {
                let opt = {};
                opt.label = value.value;
                opt.id = value.id;
                opt.value = value.id;
                opt.head = value.head;
                opt.bu = value.bu;
                serviceName.push(opt);
            });
            // for practicehead
            let practiceHead = [];
            this.props.pmoFilter.directors.forEach((id) => {
                practiceHead.push(id);
            });
            let buName = [];
            this.props.pmoFilter.directors.forEach((value) => {
                let opt = {};
                opt.label = value.bu;
                //opt.value = value.bu;
                opt.value = value.bu_id;
                opt.bu_id = value.bu_id;
                opt.id = value.id;
                opt.bu = value.bu;
                opt.name = value.name;
                if (this.state.loginUserData && (this.state.loginUserData.role_id !== 1 && this.state.loginUserData.role_id !== 8
                    && this.state.loginUserData.role_id !== constants.HR && this.state.loginUserData.role_id !== 4 && this.state.loginUserData.role_id !== 10)) {//default bu set
                    if (value.bu_id === this.state.loginUserData?.business_unit?.id) {
                        buName.push(opt);
                        let selectBu = buName;
                        this.getReportByHead(selectBu);
                        serviceName = [];
                        allChapter = [];

                    }
                    /* if (this.state.selectBuFilter && (this.state.selectBuFilter.value == 'all' || this.state.selectBuFilter.value == 'All')) {
                        serviceName = [];
                        allChapter = [];
                    } */
                }
                else {
                    buName.push(opt);
                    /* if (this.props.isEditFlag) {
                        let option = {
                            label: this.props.editQuestion.bu_name,
                            value: this.props.editQuestion.business_unit,
                            bu_id: parseInt(this.props.editQuestion.buId[0], 10),
                            id: parseInt(this.props.editQuestion.buHead[0], 10),
                            bu: this.props.editQuestion.bu_name,
                        };
                        this.getReportByHead(option);
                        // this.getReportByHead(option);
                    } */
                }
            });
            if (this.state.loginUserData && (constants.allowId.includes(this.state.loginUserData.role_id))) {
                let optionAll = { label: 'All', value: 'all', };
                buName.unshift(optionAll);
            }

            let selectService;

            if (this.state.selectBuFilter.length === 0) { //disable option on pageload
                serviceName = [];
                allChapter = [];
                this.setState({
                    chapterLead: allChapter,
                    // chapterLeadValue: allChapter
                    serviceName: serviceName,
                    buName: buName,
                });
            } else {
                if (this.state.selectBuFilter.value !== "all") {
                    serviceName = serviceName.filter(object => {
                        return object.value !== 'all';
                    });
                }
                this.setState({
                    practiceHead: practiceHead,
                    buName: buName,
                    report_all_practices: this.props.pmoFilter.practices,
                    qes_list: this.props.pmoFilter.qes_list,
                    serviceName: serviceName,
                    // selectBuFilter:this.state.role_id === 10 ? buName :[],
                    chapter: allChapter,
                    chapterLead: allChapter
                    // chapterLeadValue: allChapter
                });
                this.getChapterLead(this.state.selectService);
            }

        }, (error) => {
            this.setState({
                usersService: [],
                snameArray: [],
                bu_id: '',
                // selectBuFilter: [],
                sname: [],
                allService: []
            })
        });
    };
    /* filter practice based on service and head */
    filterService = (e) => {
        // let chaptereArray = [];
        let serviceAll = { label: 'All', value: 'all', };
        let allOption = { id: 'All', label: 'All', value: 'All', head: 'All', bu: 'ALL' };
        let serviceArray = []; let chaptereArray = [];
        if (e) {
            if (this.props.isEditFlag && e.length === 0 && this.state.selectBuFilter[0]?.value === 'All') {
                serviceArray = [];
                serviceArray.unshift(serviceAll);
                chaptereArray.unshift(serviceAll);
                this.setState({
                    serviceName: serviceArray,
                    chapterLead: chaptereArray,
                }, () => {
                });
            }
            if (!this.props.isEditFlag && this.state.serviceName?.length === 0) {
                this.setState({
                    serviceName: [allOption],
                    selectChapter: []
                });
            }
            let selectedOption = e;
            let chapterLead = [];
            let hideChapter = false;
            this.state.chapterClone.forEach((lead) => {
                if (this.state.loginUserData && (!constants.allowId.includes(this.state.loginUserData.role_id))) {
                    if (this.state.loginUserData.role_id === 10 || lead.reporting_to === this.state.loginUserData.id) {
                        selectedOption.forEach((service) => {
                            if (lead.service == service.id) {
                                chapterLead.push(lead);
                            }
                        })
                    }
                    else if (lead.label === this.state.loginUserData.chapter_name) {
                        selectedOption.forEach((service) => {
                            if (lead.service == service.id) {
                                chapterLead.push(lead);
                            }
                        })
                    }
                } else {
                    selectedOption.forEach((service) => {
                        if (lead.service == service.id) {
                            chapterLead.push(lead);
                        }
                    })
                }
            });

            if(chapterLead.length > 2 && !this.props.isEditFlag){
                let selectedService = selectedOption.map(({ id }) => id);
                let allAvailableChapters = chapterLead.map(({ value }) => value);
                let chapterLeadReport = [...new Set(chapterLead.map(({ reporting_to }) => reporting_to))];

                chapterLead.unshift({label: 'All', reporting_to: chapterLeadReport, service: selectedService, value: allAvailableChapters});
            }

            if(this.props.isEditFlag && this.state.selectChapter[0]?.label == 'All'){
                let selectedService = selectedOption.map(({ id }) => id);
                let allAvailableChapters = chapterLead.map(({ value }) => value);
                let chapterLeadReport = [...new Set(chapterLead.map(({ reporting_to }) => reporting_to))];

                this.state.selectChapter[0] = {label: 'All', reporting_to: chapterLeadReport, service: selectedService, value: allAvailableChapters}
            }

            if (chapterLead.length == 0) {
                hideChapter = true;
            }
            this.setState({
                serviceError: [],
                report: {
                    service: selectedOption
                },
                selectService: selectedOption,
                chapterLead: chapterLead,
                hideChapter: hideChapter,
                selectChapter: selectedOption.length ? this.state.selectChapter : [],
            }, () => {

            });
        }
        else {

            // selectedOption = [{ label: 'All', value: 'All', id: 'All' }];

        }
        if (this.state.loginUserData && (this.state.loginUserData.role_id !== 1 && this.state.loginUserData.role_id !== 8
            && this.state.loginUserData.role_id !== constants.HR)) {
            if (Object(e).length == 0) {
                this.setState({
                    serviceName: this.state.selectService
                })
            }
        }

    };

    getReportByHead = (e) => {
        let allService = { label: 'All', value: 'all' };
        if (e != undefined) {
            let lServiceObj = {
                bu_id: e.bu_id,
                head_id: e.label,
            }
            if (e.label === 'All') {
                lServiceObj['selectBuFilter'] = e;
                lServiceObj['selectService'] = allService
                lServiceObj['selectChapter'] = allService
                lServiceObj['chapterLeadValue'] = e.label;
                this.setState(lServiceObj, () => {
                    this.getBuFilter(this.state);
                });
            } else {
                this.setState({
                    bu_id: e.bu_id,
                    head_id: e.label,
                    selectBuFilter: e,
                    selectService: [],
                    selectChapter: this.state.isBuFiltered ? this.state.selectChapter : [],
                    isBuFiltered: false,
                    chapter: '',
                    chapterLeadValue: ''

                }, () => {
                    this.getBuFilter(this.state);
                });
            }
        } else {
            this.getBuFilter(this.state);
        }

    };

    /* business unit filter function  */
    getBuFilter = (e) => {
        let serviceArray = []; let chaptereArray = [];
        let service_head = [];
        if (this.state.head_id != "All") {
            // for BU filter    
            this.state.snameArray.forEach((service) => {
                if (e.bu_id == service.bu) {
                    service_head.push(service);
                }
            });

            this.setState({
                serviceName: [],
                // selectService: [],
                report: {
                    service: []
                }
            })

        } else {
            // when All BU is selected
            this.setState({
                serviceName: [],
                // selectService: [],
                // selectBuFilter: []
            })
            if (this.state.report.service.length == 0) {
                this.state.snameArray.forEach((service) => {
                    service_head.push(service);
                });
            } else {
                service_head = this.state.sname;
                this.setState({
                    report: {
                        service: []
                    }
                })
            }
        }

        service_head.forEach((value) => {
            let opt = {};
            opt.label = value.value;
            opt.id = value.id;
            opt.value = value.id;
            // opt.value = value.value;
            opt.head = value.head;
            opt.bu = value.bu;
            if (this.state.loginUserData && (this.state.loginUserData.role_id !== 1 && this.state.loginUserData.role_id !== constants.HR && this.state.loginUserData.role_id !== 8 && this.state.loginUserData.role_id !== 10)) {
                if (value.id === this.state.loginUserData?.service) { //set default service 
                    serviceArray.push(opt);
                }
            }
            else {
                serviceArray.push(opt);
            }
        });
        let serviceAll = { label: 'All', value: 'all', };
        if (this.state.selectBuFilter.value === 'all') { //for all filter
            serviceArray = [];
            serviceArray.unshift(serviceAll);
            chaptereArray.unshift(serviceAll);
            this.setState({
                serviceName: serviceArray,
                chapterLead: chaptereArray,
            }, () => {
            });
        }
        this.setState({
            serviceName: serviceArray,
            chapterLead: chaptereArray,
            // selectChapter: chaptereArray,
            businessError: [],
        }, () => {
        });
    }

    /**
     * Get the chapter lead list
     */
    getChapterLead = (selectService) => {
        let chapter = [];
        this.props.getChapterLead().then((response) => {
            this.props.chapterLeadList.length && this.props.chapterLeadList.map(item => {
                chapter.push({
                    label: item.chapter_name, value: item.chapter_name, service: item.service, reporting_to: item.reporting_to,
                });
            });
            let chapterAll = { label: 'All', value: 'all' };
            chapter.unshift(chapterAll);
            this.setState(
                {
                    chapterLead: chapter,
                    chapterClone: chapter,
                },
                () => {
                    this.filterService(selectService);
                }
            );
        }, (error) => {
            this.ToastAlert("error", error);
        });
    };

    /**
     * 
     * @param {*} get the start date and end date  
     */
    getDateaAndBatch = () => {
        let startDate, endDate, batch;
        this.props.getStartEndDate().then((response) => {
            this.props.dateList.length && this.props.dateList.map(item => {
                if (item.status == 0) {
                    startDate = moment(item.start_date).utcOffset('+05:30').format('DD-MM-YYYY');
                    endDate = moment(item.end_date).utcOffset('+05:30').format('DD-MM-YYYY');
                    batch = item.batch;
                }
            });
            this.setState(
                {
                    batch: batch,
                    startQuesDate: startDate,
                    endQuesDate: endDate
                },
            );
        }, (error) => {
            this.ToastAlert("error", error);
        });
    };
    /**
     * Get the Level list
     */
    getLevel = () => {
        this.props.getLevel().then((response) => {
            let levelArray = [];
            if (this.state.loginUserData.role_id == 1 || this.state.loginUserData.role_id == 8 || this.state.loginUserData.role_id == constants.HR) {
                this.props.levelList.length && this.props.levelList.map((item, key) => {
                    levelArray.push({
                        label: item.emp_level, value: item.emp_level
                    });
                });
                let levelAll = { label: 'All', value: 'all' };
                levelArray.unshift(levelAll);
                levelArray.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
                this.setState({ level: levelArray });
            } else {
                const userLevel = parseInt(this.state.loginUserData.emp_level.split('-')[1].trim());
                const filteredLevels = this.props?.levelList.filter(item => {
                    const numericPart = item.emp_level.split('-')[1]?.trim();
                    const level = numericPart ? parseInt(numericPart) : null;
                    return level !== null && level < userLevel;
                });
                filteredLevels.map((item, key) => {
                    levelArray.push({
                        label: item.emp_level, value: item.emp_level
                    });
                });
                levelArray.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
                this.setState({ level: levelArray });
            }
        }, (error) => {
            this.ToastAlert("error", error);
        });
    };


    /**
     * get values in chapter
     * @param {*} chapterOption 
     */
    handleChapter = (chapterOption) => {
        let chapterArray = [];
        let isChapterAll = false;
        let allChapter = { label: "All", reporting_to: "All", service: 'All', value: "All" };
        if (chapterOption != undefined || chapterOption != null) {
            if (chapterOption.value === 'all') {
                this.setState({ selectChapter: [chapterOption] });
            } else {
                chapterOption.forEach((item) => {
                    if(Array.isArray(item.value)){
                        isChapterAll = true;
                        chapterArray = item.value;
                    }
                    else{
                        chapterArray.push(item.value);
                    }
                })
                // If any other option is selected, clear "All" and set the selected option
            }
        }
        if (this.props.isEditFlag && chapterOption?.length === 0 && this.props.editQuestion.is_chapter_all==0 && this.state.selectChapter[0]?.value === 'All') {
            this.setState({
                chapterLead: [allChapter],
            });
        }
        else if(this.state.chapterLead.length > 2 && this.props.isEditFlag && this.state.chapterLead[0]?.label != 'All'){
            let selectedService = this.state.selectService.map(({ id }) => id);
            let allAvailableChapters = this.state.chapterLead.map(({ value }) => value);
            let chapterLeadReport = [...new Set(this.state.chapterLead.map(({ reporting_to }) => reporting_to))];

            this.state.chapterLead.unshift({label: 'All', reporting_to: chapterLeadReport, service: selectedService, value: allAvailableChapters});
            this.setState({
                chapterLead: this.state.chapterLead,
            });
        }
        this.setState({
            isChapterAll: isChapterAll,
            chapterLeadValue: chapterArray,
            selectChapter: chapterOption,
            chapterError: []
        })
    };

    /**
     * get values in Level
     * @param {*} chapterOption 
     */
    handleLevel = (selectedOption) => {
        if (selectedOption?.value === 'all') {
            this.setState({ selectLevel: [selectedOption] });
        } else {
            // If any other option is selected, clear "All" and set the selected option
            this.setState({ selectLevel: selectedOption });
        }
        // this.setState({
        //     selectLevel: level,
        //     levelError: []
        // })
    };




    /**
    * get values for question type
    * @param {*} event 
    */
    handleQuestionType = (event, type) => {
        let isActive;
        if (type == 1) {
            isActive = type;
        } else if (type == 2) {
            isActive = type;
        } else {
            isActive = type;
        }
        let isTypeChange, selectedType, isOption, isComments;
        if (type == 1) {
            selectedType = true;
            isOption = false;
            isComments = false;
        } else if (type === 2) {
            selectedType = true;
            isComments = true;
        } else {
            selectedType = false;
        }
        if (type == 3) {
            selectedType = true;
            isOption = true;
            isComments = false;
        }
        this.setState({
            questionType: (type) ? type : this.props.editQuestion.question_type,
            isQuestion: selectedType,
            isOption: isOption,
            isType: true,
            isTypeChange: isTypeChange,
            isActive: isActive,
            isComments: isComments
        })
        event.preventDefault();
    };

    /**
    * get values for question 
    * @param {*} type 
    */
    handleQuestion = (event, editor) => {
        let questionData;
        if (editor.getData() != null) {
            questionData = editor.getData();
        } else {
            questionData = this.props.editQuestion.question;
        }
        this.setState({
            question: questionData
        });
    }

    /**
     * Additional input option
     * @param {*} index 
     * @param {*} event 
     */
    handleInputChange = (index, event) => {
        const updatedFields = [...this.state.inputFields];
        updatedFields[index] = event.target.value;
        this.setState({ inputFields: updatedFields });
    };

    /**
    * Additional input option
    * @param {*} index 
    * @param {*} event 
    */
    handleEditChange = (index, event) => {
        const updatedFields = [...this.state.isOptionArray];
        updatedFields[index] = event.target.value;
        this.setState({ isOptionArray: updatedFields });
    };

    handleCheckboxChange = (e) => {
        let isChecked = e.target.checked; // Get the new checked state
        this.setState({ isChecked: isChecked }); // Update the state with the new value
    };

    handleCommentChange = (e) => {
        let isChecked = e.target.checked; // Get the new checked state
        this.setState({ isRating: isChecked }); // Update the state with the new value
    };

    /**
     * Additional input option to append
     * @param {*} index 
     * @param {*} event 
     */
    addInputField = (e) => {
        this.setState((prevState) => ({
            inputFields: [...prevState.inputFields, ''],
        }));
        e.preventDefault();
    };
    /**
     * Additional input option to append
     * @param {*} index 
     * @param {*} event 
     */
    editInputField = (e) => {
        this.setState((prevState) => ({
            isOptionArray: [...prevState.isOptionArray, '']
        }));
        if (this.props.isEditFlag) {
            this.setState({
                addOption: true
            })
        }
        e.preventDefault();
    };

    isValidDate = (dateStr) => {
        const year = dateStr.getFullYear();
        if (year >= 1000 && year <= 9999) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * 
     * @returns Validating the form
     */
    validateForm = () => {
        const errors = {};
        if (this.state.selectBuFilter.length == 0) {
            errors.businessError = 'Business unit is required.';
        }
        if (this.state.serviceName?.length != 0 && this.state.selectService.length == 0) {
            errors.serviceError = 'Service is required.';
        }
        
        if (this.state.chapterLead?.length != 0 && this.state.selectChapter.length == 0) {
            errors.chapterError = 'Chapter is required.';
        }
        if ((this.state.question == '') || (this.props.isEditFlag && this.props.editQuestion.question == '')) {
            errors.questionError = 'Question is required.';
        }
        if (!this.props.isEditFlag) {
            if (this.state.isActive === false) {
                errors.questionTypeError = 'Question type is required.';
            }
        }
        if (!this.props.isEditFlag && this.state.questionType === 3) {
            errors.optionError = [];
            this.state.inputFields.forEach((item, index) => {
                if (!this.props.isEditFlag && (item === '' || item === undefined || item === null)) {
                    errors.optionError[index] = 'Option is required.';
                }
                if (this.props.isEditFlag && index !== 0 && (item === '' || item === undefined || item === null)) {
                    errors.optionError[index] = 'Option is required.';
                }

            });
            if (errors.optionError.length === 0) {
                delete errors.optionError;
            }

        }
        if ((this.props.isEditFlag && this.props.editQuestion.question_type === 3)) {
            errors.optionError = [];
            this.state.isOptionArray.forEach((item, index) => {
                if (this.props.isEditFlag && (item === '' || item === undefined || item === null)) {
                    errors.optionError[index] = 'Option is required.';
                }
                if (this.props.isEditFlag && index !== 0 && (item === '' || item === undefined || item === null)) {
                    errors.optionError[index] = 'Option is required.';
                }

            });
            if (errors.optionError.length === 0) {
                delete errors.optionError;
            }


        }
        /* if (this.props.isEditFlag && this.props.editQuestion.question_type === 3) {
            const optionErrors = this.state.isOptionArray.map((item, index) => {
                if (index === 0 && (item === '' || item === undefined || item === null)) {
                    return 'First option is required.';
                }
                if (index !== 0 && (item === '' || item === undefined || item === null)) {
                    return `Option ${index + 1} is required.`;
                }
                return null;
            }).filter(error => error !== null);

            if (optionErrors.length > 0) {
                errors.optionError = optionErrors;
            }
        } */

        if (this.state.selectLevel.length == 0) {
            errors.levelError = 'Level is required.';
        }
        if ((this.state.filter_start_date == null) || (this.state.filter_start_date === '')) {
            errors.startDateError = 'Start Date is required.';
        }
        if ((this.state.filter_end_date == null) || (this.state.filter_end_date === '')) {
            errors.endDateError = 'End Date is required.';
        }
        if (this.state.filter_start_date != null && !this.isValidDate(this.state.filter_start_date)) {
            errors.startDateError = 'Invalid  Start Date.';
        }
        if (this.state.filter_end_date != null && !this.isValidDate(this.state.filter_end_date)) {
            errors.endDateError = 'Invalid  End Date.';
        }
        this.setState({
            businessError: errors.businessError,
            serviceError: errors.serviceError,
            chapterError: errors.chapterError,
            questionError: errors.questionError,
            optionError: errors.optionError,
            questionTypeError: errors.questionTypeError,
            levelError: errors.levelError,
            startDateError: errors.startDateError,
            endDateError: errors.endDateError


        }); // Set validation errors in state
        return Object.keys(errors).length === 0; // Return true if no errors
    }

    handleAddMoreQuestion = async () => {
        this.setState({ isAddMoreQuestion: true }, () => {
            this.setState({
                existingSelectedBu: (this.state.selectBuFilter != '') ? this.state.selectBuFilter : [],
                existingSelectedLos: this.state.selectService,
                existingSelectedChapter: this.state.chapterLeadValue,
                existingSelectedLevel: this.state.selectLevel,
                mandatory: '',
                isChecked: this.props.isEditFlag ? this.props.editQuestion.mandatory : false,
                isComments: false,
                isRating: this.props.isEditFlag ? this.props.editQuestion.comments_rating : false,
                optionActive: false,
                question: '',
                isRating: '',
                questionType: getBackEndConstant().questionType,
                isQuestion: false,
                addOption: this.props.isEditFlag ? false : true,
                isOptionArray: this.props.isEditFlag ? this.props.editQuestion.is_option : [],
                isOption: false,
                inputFields: [''],
                isActive: false,
                isSaved: false,

            })
        });
    }
    /**
     * save pms Question
     */
    saveQuestion = async (event) => {
        let concatOption = this.state.isOptionArray.concat(this.state.inputFields);
        event.preventDefault();
        this.setState({ optionActive: true });
        let dataObj = {}; let pmsData = {};
        dataObj.startDate = this.state.startQuesDate;
        dataObj.endDate = this.state.endQuesDate;
        dataObj.id = (this.props.isEditFlag) ? this.props.editQuestion.id : '';
        dataObj.chapterLead = [];
        dataObj.is_chapter_all = this.state.isChapterAll;
        if (this.state.selectChapter?.length) {
            this.state.selectChapter?.forEach((item) => {
                if(Array.isArray(item.value)){
                    dataObj.chapterLead = item.value
                }
                else{
                    dataObj.chapterLead.push(item.value);
                }
            });
        }
        dataObj.business_unit = (this.state.selectBuFilter != '') ? this.state.selectBuFilter : [];
        dataObj.los = this.state.selectService;
        dataObj.question = (this.props.isEditFlag && (this.state.question == null || this.state.question == '')) ? this.props.editQuestion.question : this.state.question;
        dataObj.question_type = (!this.props.isEditFlag) ? this.state.questionType : this.props.editQuestion.question_type;
        let questionArray = this.props.isEditFlag ? concatOption : this.state.inputFields;
        dataObj.questionOption = questionArray.filter((item) => !!item);
        dataObj.level = this.state.selectLevel;
        let checked = this.state.isChecked;
        let isRating = (this.state.isRating === false) ? 0 : 1;
        dataObj.field = checked;
        dataObj.commentsRating = isRating;
        pmsData['data'] = dataObj;

        if (!this.validateForm()) {
            return; // Stop submission if form is not valid
        }
        try {
            await this.props.saveQuestion(pmsData);
            this.props.manageQuestion();
            if (this.props.isEditFlag) {
                // this.props.manageQuestion();
                this.handleModalClose();
                this.ToastAlert('success', "Question Updated successfully");
            } else {
                this.ToastAlert('success', "Question Saved successfully");
            }
            this.setState({
                isOption: false,
                question: null,
                isSaved: true,
                questionType: getBackEndConstant().questionType,
                isQuestion: false,
                addOption: this.props.isEditFlag ? false : true,
                isOptionArray: this.props.isEditFlag ? this.props.editQuestion.is_option : [],
                inputFields: [''],
                isActive: false,
                mandatory: '',
                isComments: false,
                isChecked: false,
            });
        } catch (error) {
            this.ToastAlert('error', "Something went wrong");
        }
    };

    /**
     * 
     * @returns delete question options
     */
    removeInputField = (index, event) => {
        const { inputFields } = this.state;
        if (inputFields.length > 1) {
            this.setState(prevState => {
                const updatedInputFields = prevState.inputFields.filter((_, i) => i !== index);
                event.preventDefault();
                return { inputFields: updatedInputFields };
            });
        } else {
            // Notify the user about the error
            this.ToastAlert("error", "MCQ Question must have one option");
            if (event) {
                event.preventDefault();
            }
        }
    };


    /**
     * 
     * @returns delete question options
     */

    removeOptionField = (index, event) => {
        const { isOptionArray } = this.state;
        if (isOptionArray.length > 1) {
            this.setState(prevState => {
                const updatedInputFields = prevState.isOptionArray.filter((_, i) => i !== index);
                event.preventDefault();
                return { isOptionArray: updatedInputFields };
            });
        } else {
            // Notify the user about the error
            this.ToastAlert("error", "MCQ Question must have one option");
            if (event) {
                event.preventDefault();
            }
        }
    };



    /**
     * set start date
     * @param {*} e 
     */
    setStartDateForFilter = (e) => {
        let today = new Date(e);
        let defaultDate = new Date('1970-01-01');
        if (today.getTime() === defaultDate.getTime()) {
            today = null;
        }
        this.setState({
            filter_start_date: today,
            filter_end_date: null,
        });
    };

    /**
     * set end date
     * @param {*} e 
     */

    setEndDateForFilter = (e) => {
        let today = new Date(e);
        let defaultDate = new Date('1970-01-01');
        if (today.getTime() === defaultDate.getTime()) {
            today = null;
        }
        this.setState({
            filter_end_date: today,
        });
    };

    defaultAddQuestionForm = (options, losOptions, chapterOptions) => {
        return (
            <>
                <div className="form-group">
                    <>
                        <label className='text-black font-bold' htmlFor="bu">Business Unit</label>
                        <div className='selectpicker_dropdown hide_bar selectpicker_dropdown_orange'>
                            <Select
                                className="basic-single-select"
                                classNamePrefix={"select_dropdown"}
                                placeholder="Select"
                                isSearchable={true}
                                onChange={(e) => this.getReportByHead(e)}
                                value={this.state.selectBuFilter}
                                options={this.state.buName}
                                defaultValue={this.state.selectBuFilter}
                            />
                        </div>
                        {this.state.businessError && (
                            <div className="text-danger">
                                {this.state.businessError}
                            </div>
                        )}
                    </>
                </div>

                <div className="form-group">
                    <>
                        <label className='text-black font-bold'>Line of Service</label>
                        <div className='selectpicker_dropdown hide_bar selectpicker_dropdown_orange'>
                            <Select
                                className="basic-single-select"
                                classNamePrefix={"select_dropdown"}
                                placeholder="Select"
                                isSearchable={true}
                                onChange={(e) => this.filterService(e)}
                                value={this.state.selectService}
                                options={losOptions}
                                isMulti
                            >
                            </Select>
                        </div>
                        {this.state.serviceError && (
                            <div className="text-danger">
                                {this.state.serviceError}
                            </div>
                        )}
                    </>
                </div>

                <div className="form-group">
                    {/* {(!this.state.hideChapter) || (this.props.isEditFlag && (this.props.editQuestion.chapter && this.props.editQuestion.chapter===''))  && ( */}
                    {/* {(!this.state.hideChapter) && */}
                    <div>
                        <label className='text-black font-bold'>Chapter</label>
                        <div className='selectpicker_dropdown hide_bar selectpicker_dropdown_orange'>
                            <Select
                                className="basic-single-select"
                                classNamePrefix={"select_dropdown"}
                                options={chapterOptions}
                                value={this.state.selectChapter}
                                onChange={(e) => this.handleChapter(e)}
                                placeholder="Select"
                                isMulti
                            />
                        </div>
                    </div>
                    {/* } */}

                    {this.state.chapterError && (
                        <div className="text-danger">
                            {this.state.chapterError}
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label className='text-black font-bold'>Level</label>
                    <div className='selectpicker_dropdown hide_bar selectpicker_dropdown_orange'>
                        <Select
                            className="basic-single-select"
                            classNamePrefix={"select_dropdown"}
                            isClearable
                            isSearchable={true}
                            onChange={(e) => this.handleLevel(e)}
                            placeholder="Select"
                            options={options}
                            value={this.state.selectLevel}
                            isMulti
                        />

                    </div>
                    {this.state.levelError && (
                        <div className="text-danger">
                            {this.state.levelError}
                        </div>
                    )}
                </div>
            </>
        )
    }

    clearFormData = () => {
        this.setState({
            businessError: [],
            serviceError: [],
            chapterError: [],
            questionError: [],
            optionError: [],
            questionTypeError: [],
            levelError: [],
            startDateError: [],
            endDateError: [],
            selectBuFilter: [],
            selectChapter: [],
            selectLevel: [],
            mandatory: '',
            selectService: this.props.isEditFlag ? [] : this.props.selectedFilter?.filteredLos,
            selectBuFilter: this.props.isEditFlag ? [] : (this.props.selectedFilter?.filteredBU || []),
            buFilter: this.props.isEditFlag ? [] : this.props.selectedFilter?.filteredBU,
            selectChapter: this.props.isEditFlag ? [] : this.props.selectedFilter?.filteredChapter,
            isChecked: this.props.isEditFlag ? this.props.editQuestion.mandatory : false,
            isComments: false,
            isRating: this.props.isEditFlag ? this.props.editQuestion.comments_rating : false,
            optionActive: false,
            question: '',
            isRating: '',
            questionType: getBackEndConstant().questionType,
            isQuestion: false,
            addOption: this.props.isEditFlag ? false : true,
            isOptionArray: this.props.isEditFlag ? this.props.editQuestion.is_option : [],
            isOption: false,
            inputFields: [''],
            isActive: false,
            isSaved: false,
            isManageQuestionFiltered: true,
        });
    }

    handleModalClose = () => {
        this.clearFormData();
        this.props.handleCloseModal();
    };


    render() {
        var longMonthName = this.state.batch && this.state.batch == 1 ? 'April' : 'October';
        if(this.state.startQuesDate!=''){
            var formattedstartDate = new Date(moment(this.state.startQuesDate, 'DD-MM-YYYY').
            format('YYYY-MM-DD').toString() + " 0:00:00");
            var monthName = new Intl.DateTimeFormat("en-US", { month: "long" }).format;
            //var longMonthName = monthName(formattedstartDate);
        }
        
        const { selectLevel, level, selectService, serviceName, selectChapter, chapterLead } = this.state;
        const isAllSelected = selectLevel?.some((option) => option.value === 'all' || option.value === 'All');
        let isAllLosSelected = [], isAllChapterSelected = [];
        if (Array.isArray(selectService)) {
            isAllLosSelected = selectService?.some((service) => service.value === 'all' || service.value === 'All');
        }
        if (Array.isArray(selectChapter)) {
            isAllChapterSelected = selectChapter?.some((chapter) => chapter.label === 'all' || chapter.label === 'All');
        }
        const isAllDisabled = selectLevel && selectLevel.length > 0;
        const isAllLosDisabled = selectService && selectService.length > 0;
        const isAllChapterDisabled = selectChapter && selectChapter.length > 0;
        const options = level.map((option) => ({
            ...option,
            isDisabled: (isAllSelected && option.value !== 'all') || (isAllDisabled && option.value === 'all'),
        }));
        const losOptions = serviceName.map((option) => ({
            ...option,
            isDisabled: (isAllLosSelected && option.value !== 'all') || (isAllLosDisabled && option.value === 'all'),
        }));
        const chapterOptions = chapterLead.map((option) => ({
            ...option,
            isDisabled: (isAllChapterSelected && option.label !== 'All') || (isAllChapterDisabled && option.label === 'All')
        }));
        const [, , year] = this.state.startQuesDate.split('-');

        return (
            <div >
                {this.state.loading && (
                    <div id="preloader">
                        <div id="status"></div>
                    </div>
                )}
                <form className="custom-form qst-form" onSubmit={this.saveQuestion}>
                    <div className='batchreview mb-3'>
                        <div className='pull-left'>
                            <b className='text-black'>Batch : </b> {longMonthName} &nbsp;
                            {this.state.startQuesDate ? year : ''}
                        </div>
                        <div className='pull-right'>
                            <div><b className='text-black'>Review Start Date : </b>
                                <span> {this.state.startQuesDate ? this.state.startQuesDate : ''}</span></div>
                            <div style={{ marginLeft: '7px'}}><b className='text-black'>Review End Date :</b> <span>{this.state.endQuesDate ? this.state.endQuesDate : ''} </span></div>
                        </div>
                    </div>
                    <div>
                        {this.defaultAddQuestionForm(options, losOptions, chapterOptions)}

                        {(this.state.loginUserData.role_id === constants.HR || this.state.loginUserData.role_id === 1) && (
                            <div>
                                <div className="form-group mandatory-check mb-2 mt-4">
                                    <input type="checkbox"
                                        className="check m-r-5"
                                        checked={this.state.isChecked} // Set the checked value from state
                                        onChange={(e) => this.handleCheckboxChange(e)}
                                    />{" "}
                                    <label className='text-black font-bold'>Is Mandatory Question</label>
                                </div>
                            </div>
                        )}

                    </div>
                    <br></br>
                    <div className="form-group">
                        <div className='question-type'>
                            {!this.props.isEditFlag && !this.state.isSaved &&
                                <div className='btn-qst-type mt-2 mb-2'>
                                    <label className='text-white font-bold'></label>
                                    <button id="text" className={"btn btn-primary " + ((this.state.isActive == 1) ? "active-btn" : "")} onClick={(e) => this.handleQuestionType(e, 1)}><i class="fa fa-text-width" aria-hidden="true"></i> Text</button> &nbsp;
                                    <button className={"btn btn-primary " + ((this.state.isActive == 2) ? "active-btn" : "")} onClick={(e) => this.handleQuestionType(e, 2)}><i class="fa fa-thumbs-up" aria-hidden="true"></i> Rating</button>&nbsp;
                                    <button className={"btn btn-primary " + ((this.state.isActive == 3) ? "active-btn" : "")} onClick={(e) => this.handleQuestionType(e, 3)}><i class="fa fa-question-circle-o" aria-hidden="true"></i> MCQ</button>&nbsp;
                                </div>
                            }
                        </div>
                        {this.state.questionError && (
                            <div className="text-danger">
                                {this.state.questionTypeError}
                            </div>
                        )}
                    </div>
                    {
                        this.state.isQuestion && (
                            <div className="form-group">
                                <label className='text-black font-bold'>Question</label>
                                <CKEditor
                                    editor={ ClassicEditor }
                                    config={ constants.ckeditorConfig}
                                    data={this.props.isEditFlag ? this.props.editQuestion.question : this.state.question} 
                                    onChange={this.handleQuestion}
                                />
                                {/* <textarea defaultValue={this.props.isEditFlag ? this.props.editQuestion.question : this.state.question} className="form-control addquestion" onChange={(e) => this.handleQuestion(e)} /> */}
                                {this.state.questionError && (
                                    <div className="text-danger">
                                        {this.state.questionError}
                                    </div>
                                )}
                            </div>
                        )
                    }
                    <div>
                        {this.state.isComments && (
                            <div className="form-group mandatory-check mb-2 mt-4">
                                <input type="checkbox"
                                    className="check m-r-5"
                                    checked={this.state.isRating} // Set the checked value from state
                                    onChange={(e) => this.handleCommentChange(e)}
                                />{" "}
                                <label className='text-black font-bold'>Is Comment Mandatory</label>

                            </div>
                        )}
                        {(this.props.isEditFlag && this.props.editQuestion.question_type === 2) && (
                            <div className="form-group mandatory-check mb-2 mt-4">
                                <input type="checkbox"
                                    className="check m-r-10"
                                    checked={this.state.isRating} // Set the checked value from state
                                    onChange={(e) => this.handleCommentChange(e)}
                                />{" "}
                                <label className='text-black font-bold'>Is Rating comment Mandatory</label>

                            </div>
                        )}
                    </div>
                    {/* start Edit question */}

                    <div className={"add-deletebtn " + (this.state.optionActive ? "active" : "")} >
                        {this.props.isEditFlag && this.state.isOption && (

                            <div className='edit-qstafter'>
                                <div>

                                    {this.state.isOptionArray && this.state.isOptionArray.map((field, index) => (
                                        <div className='addfield-content afteredit' key={index}>

                                            <input
                                                id={index}
                                                type="text"
                                                value={field}
                                                placeholder={'Option' + (index + 1)}
                                                onChange={(event) => this.handleEditChange(index, event)}
                                            />
                                            <button
                                                id={index}
                                                className="btn btn-primary"
                                                onClick={(e) => this.removeOptionField(index, e)}
                                            >
                                                <i className="fs-16 fa fa-trash"></i>
                                            </button>
                                            {this.props.isEditFlag && this.state.optionError && this.state.optionError[index] && (
                                                <div className="text-danger text-left pb-2">
                                                    {this.state.optionError[index]}
                                                </div>
                                            )}
                                        </div>
                                    ))}

                                    {/* end edit question */}
                                </div>

                            </div>
                        )
                        }
                        {/* start save question */}
                        {
                            this.state.isOption && (
                                <div>
                                    <div className='add_delete'>
                                        {this.state.inputFields.map((field, index) => (//new
                                            <div className='w-100'>

                                                {(((index != 0) && this.props.isEditFlag) || (!this.props.isEditFlag)) &&
                                                    <div className='addfield-content w-100 d-flex'>
                                                        <input key={index} id={index} type="text" value={field} placeholder={'Option' + (index + 1)}
                                                            onChange={(event) => this.handleInputChange(index, event)}
                                                        />
                                                        {(<button key={index} id={index} className="btn btn-primary"
                                                            onClick={(e) => this.removeInputField(index, e)}>
                                                            <i className="fs-16 fa fa-trash"></i>
                                                        </button>)}&nbsp;
                                                    </div>
                                                }
                                                {!this.props.isEditFlag && this.state.optionError && this.state.optionError[index] && (
                                                    <div className="text-danger text-left pb-2">
                                                        {this.state.optionError[index]}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            )
                        }
                        {this.state.isOption &&
                            <div className='add-delete-btn'>
                                <button className={"btn btn-primary add_btns mb-3"}
                                    onClick={(e) => this.props.isEditFlag ? this.editInputField(e) : this.addInputField(e)}>
                                    <i className="fs-16 fa fa-plus"></i> Add Option</button>
                            </div>
                        }
                    </div>
                    <div>
                    </div>
                    {/* end save question */}
                    <div className='text-center'>
                        {!this.state.isSaved && <Button
                            variant="primary"
                            type="submit"
                            onClick={this.saveQuestion}
                            style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '130px', height: '42px', fontSize: '14px' }}
                        >
                            {this.props.isEditFlag ? 'UPDATE' : 'SAVE'}
                        </Button>}

                        {!this.props.isEditFlag && this.state.isSaved &&
                            (<><div>
                                <div className="form-group mandatory-check mb-2 mt-4">

                                    <label className='text-black font-bold'>Do you want to add more questions ? </label>
                                </div>
                            </div>

                                < Button
                                    variant="primary"
                                    onClick={this.handleAddMoreQuestion}
                                    style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '130px', height: '42px', fontSize: '14px' }}
                                >
                                    {"YES"}
                                </Button></>)
                        }

                        &nbsp;&nbsp;&nbsp;
                        {!this.props.isEditFlag && this.state.isSaved &&
                            (<>
                                <Button
                                    onClick={this.handleModalClose}
                                    value="back"
                                    className="me-2"
                                    style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '130px', height: '42px', fontSize: '14px' }}
                                >
                                    NO
                                </Button></>)
                        }
                        {!this.state.isSaved && <Button
                            onClick={this.handleModalClose}
                            value="back"
                            className="me-2"
                            style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '130px', height: '42px', fontSize: '14px' }}
                        >
                            CANCEL
                        </Button>}
                    </div>
                </form >
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        chapterLeadList: state.pmsDetails.chapterLead,
        saveQuestion: state.pmsDetails.saveQuestion,
        pmoFilter: state.reportFilterDetails.reportFilter,
        loginDetails: state.loginDetails,
        addClass: state.pmsDetails.addClass,
        levelList: state.pmsDetails.getLevel,
        dateList: state.pmsDetails.getStartEndDate,
    };
};

const mapDispatchToProps = {
    getChapterLead,
    saveQuestion,
    getReportFilter,
    getLoginUserPermission,
    getLoginUser,
    setAddClass,
    getLevel,
    getStartEndDate
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddQuestion);