import React, { Component } from "react";
import { Row, Modal, Button, Form, FormControl } from "react-bootstrap";
import { getBackEndConstant } from "../../Config/Constant";
import { saveSettings, getDates, updateStatus } from "../../Action";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import AddUpdateSettings from "./AddUpdateSettings";
import "./pms.css";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class PmsSettingGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSettingModal: false,
      startDate: new Date(),
      endDate: new Date(),
      filter_start_date: new Date(),
      months: ["April", "October"],
      year: "2023",
      month: "0"
    };
  }

  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  handleCloseModal = () => {
    this.setState({
      showSettingModal: false,
      startDate: new Date(),
      endDate: new Date(),
      batch: "April",
      month: "0",
    });
    this.props.getDates({
      data: {
        performanceDate: this.props.performanceDate.split('-')[0],
      },
    });
  };
  /**
   * Show popup for settings
   * @param {*} id
   */
  viewSettingsForm = (val, key) => {
    this.setState({
      showSettingModal: true,
      deleteQuestionId: key,
      startDate: new Date(val.start_date),
      endDate: new Date(val.end_date),
      batch: val.batch == 1 ? "April" : "October",
      month: val.batch == 1 ? "0" : "1",
      months: val.batch == 1 ? ["April"] : ["October"],
      id: val.id,
    });
  };

  /**
   * Change status
   * @param {*} id
   */
  handleCheckboxChange = (e, val, index) => {
    const isChecked = e.target.checked;
    let statusSwitch = isChecked;

    let dataObject = { id: val.id, status: statusSwitch };
    let payload = {};
    payload["data"] = dataObject;
    this.props.updateStatus(payload).then(
      (_response) => {
        this.setState({
          loading: false,
        });
        if (_response.code == 2) {
          this.ToastAlert("warning", _response.result);
        }
        else { this.ToastAlert("success", "Updated successfully"); }
        this.props.getDates({
          data: {
            performanceDate: this.props.performanceDate.split('-')[0],
          },
        });
      },
      (error) => {
        this.setState({
          loading: false,
        });
        this.ToastAlert("error", "Something went wrong");
      }
    );
  };

  render() {
    return (
      <>
        {this.state.showSettingModal && (
          <>
            <AddUpdateSettings
              settingsData={this.state}
              isEditFlag={true}
              handleCloseModal={this.handleCloseModal}
            />
          </>
        )}
        <Row>
          <div className="table-responsive white-table-class p-0">
            <div className="scrollable">
              <div className="scrollable-content">
                <table className="table m-b-0 user-grid-table">
                  <thead>
                    <tr className="theadInner default-bg">
                      <th className="text-start" style={{ width: "20%" }}>
                        Start Date
                      </th>
                      <th className="text-start" style={{ width: "20%" }}>
                        End Date
                      </th>
                      <th className="text-start" style={{ width: "20%" }}>
                        Batch
                      </th>
                      <th className="text-start" style={{ width: "10%" }}>
                        Status
                      </th>
                      <th className="text-start" style={{ width: "15%" }}>
                        Edit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props?.dataList.length > 0 ? (
                      this.props.dataList?.map((item, index) => {
                        return (
                          <tr key={`usergriditem-${index}`}>
                            <td style={{ position: "relative" }}>
                              <span
                                className={`intimate mr-5-li`}
                              ></span>
                              {(new Date(item.start_date)).toLocaleDateString('en-GB')}
                            </td>
                            <td style={{ position: "relative" }}>
                              <span
                                className={`intimate mr-5-li`}
                              ></span>
                              {(new Date(item.end_date)).toLocaleDateString('en-GB')}
                            </td>
                            <td>{item.batch == 1 ? "April" : "October"}</td>
                            <td style={{ position: "relative" }}>
                              <span
                                className={`intimate mr-5-li`}
                              ></span>
                              <div class="form-check form-switch">
                                <input
                                  class="form-check-input c-pointer"
                                  type="checkbox" role="switch" id="batch-status" name="batch-status"
                                  checked={!item.status}
                                  onChange={(e) =>
                                    this.handleCheckboxChange(e, item, index)
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <i
                                className={`fs-16 fa fa-pencil c-pointer warning-color`}
                                onClick={() =>
                                  this.viewSettingsForm(item, true)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4} className="text-center">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { saveSettings: state.pmsDetails.saveSettings };
};

const mapDispatchToProps = { saveSettings, getDates, updateStatus };

export default connect(mapStateToProps, mapDispatchToProps)(PmsSettingGrid);