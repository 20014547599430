import { pmsExportExcel } from "../Action";
import * as types from "../Utilities/Type";
const INITIAL_STATE = {
  pmsDashboard: [],
  chapterLead: [],
  saveQuestion: [],
  pmsQuestion: [],
  question: [],
  questionId: [],
  saveAnswer: [],
  getAnswer: [],
  addClass: false,
  getUserGrid: [],
  getLevel: [],
  getStartEndDate: [],
  settingDates: [],
  saveSettingsResponse: {},
  pmsReportResponse: {},
  downloadZipResponse: {},
  pmsExportExcelResponse:{}
};

export function getPmsData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.PMS_DASHBOARD:
      return {
        ...state,
        pmsDashboard: action.payload,
      };
    case types.CHAPTER_LEADER:
      return {
        ...state,
        chapterLead: action.payload,
      };
    case types.SAVE_QUESTION:
      return {
        ...state,
        saveQuestion: action.payload,
      };
    case types.GET_QUESTION:
      return {
        ...state,
        pmsQuestion: action.payload,
      };
    case types.DELETE_QUESTION:
      return {
        ...state,
        question: action.payload,
      };
    case types.EDIT_QUESTION:
      return {
        ...state,
        questionId: action.payload,
      };
    case types.SAVE_ANSWER:
      return {
        ...state,
        saveAnswer: action.payload,
      };
    case types.GET_ANSWER:
      return {
        ...state,
        getAnswer: action.payload,
      };
    case types.ADD_CLASS:
      return {
        ...state,
        addClass: action.payload,
      };
    case types.GET_USER_GRID:
      return {
        ...state,
        getUserGrid: action.payload,
      };
    case types.GET_LEVEL:
      return {
        ...state,
        getLevel: action.payload,
      };
    case types.GET_DATE:
      return {
        ...state,
        getStartEndDate: action.payload,
      };
    case types.GET_DATES:
      return {
        ...state,
        settingDates: action.payload,
      };

    case types.SAVE_SETTINGS:
      return {
        ...state,
        saveSettingsResponse: action.payload,
      };
    case types.DOWNLOAD_PMS_REPORT:
      return {
        ...state,
        pmsReportResponse: action.payload,
      };
    case types.PMS_EXPORT_EXCEL:
      return {
        ...state,
        pmsExportExcel: action.payload,
      };
    case "3":
      break;
    default:
      return state;
  }
}
