import React, { Component } from "react";
import { Row, Modal, Button, Form, FormControl } from "react-bootstrap";
import { getBackEndConstant } from "../../Config/Constant";
import { saveSettings } from "../../Action";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Swal from "sweetalert2";
import Select from "react-select";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class AddUpdateSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSettingModal: false,
      startDate: this.props.isEditFlag
        ? this.props?.settingsData?.startDate
        : new Date(),
      endDate: this.props.isEditFlag
        ? this.props?.settingsData?.endDate
        : new Date(),
      batch: this.props.isEditFlag
      ? this.props?.settingsData?.batch
      : "",
      months: this.props.isEditFlag && this.props?.settingsData?.batch === "April"
      ? ["March", "April", "May"]
      : ["September", "October", "November"],
      allMonths: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      monthsList: [
        { value: "April", label: "April" },
        { value: "October", label: "October" },
      ],
      // yearsList: [
      //   // { value: "2023", label: "2023" },
      //   { value: "2024", label: "2024" },
      //   { value: "2025", label: "2025" },
      //   { value: "2026", label: "2026" },
      // ],
      selectedYear: "",
      selectedMonth: "",
      //years: [2024, 2025, 2026],
      year: "2024",
      // month: "0",
      showSettingModal: this.props.isEditFlag ? true : false,
      isStartDate: true,
      isEndDate: true,
      id: this.props?.settingsData?.id,
    };
  }
  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }
  //   /**
  //    * Show popup for settings
  //    * @param {*} id
  //    */
  //   viewSettingsForm = (val, key) => {
  //     this.setState({
  //       showSettingModal: true,
  //       deleteQuestionId: key,
  //       startDate: new Date(val.start_date),
  //       endDate: new Date(val.end_date),
  //       batch: val.batch == 1 ? "April" : "October",
  //       month: val.batch == 1 ? "0" : "1",
  //       months: val.batch == 1 ? ["April"] : ["October"],
  //       id: val.id,
  //     });
  //   };
  setStartEndDate = (date, type) => {
    if (type == "start_date") {
      this.setState({ startDate: date });
    } else {
      this.setState({ endDate: date });
    }
  };
  getPerformanceDateYearOption = () => {
    const currentYear = new Date().getFullYear();
    const options = Array(6)
      .fill(1)
      .map((_item, index) => {
        return {
          id: index,
          label: currentYear + index,
          value: currentYear + index,
        };
      });
    return options;
  };
  /**
   *
   * @returns Validating the form
   */
  validateForm = () => {
    const errors = {};

    if (this.state.startDate == null || this.state.startDate === "") {
      errors.startDateError = "Start Date is required.";
    }
    if (this.state.endDate == null || this.state.endDate === "") {
      errors.endDateError = "End Date is required.";
    }

    if (this.state.startDate != null && this.state.startDate !== "") {
      const startDateFormat = moment(
        new Date(this.state.startDate),
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD");
      const endDateFormet = moment(
        new Date(this.state.endDate),
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD");

      if (
        moment(new Date(this.state.endDate), "YYYY-MM-DD").isBefore(
          startDateFormat
        )
      ) {
        /** invoice  future date validate*/
        errors.endDateError = "End Date should be greater than start date";
      }
    }
    this.setState({
      startDateError: errors.startDateError,
      endDateError: errors.endDateError,
    }); // Set validation errors in state
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  /**
   * save pms Question
   */
  saveSettings = async (event) => {
    event.preventDefault();
    this.setState({ optionActive: true });
    let dataObj = {};
    let pmsData = {};
    dataObj.start_date = moment(
      new Date(this.state.startDate),
      "YYYY-MM-DD"
    ).format("YYYY-MM-DD");
    dataObj.end_date = moment(
      new Date(this.state.endDate),
      "YYYY-MM-DD"
    ).format("YYYY-MM-DD");
    dataObj.id = this.state.id;
    dataObj.batch = this.state.batch == "April" ? 1 : 2;
    pmsData["data"] = dataObj;

    if (!this.validateForm()) {
      return; // Stop submission if form is not valid
    }
    try {
      await this.props.saveSettings(pmsData).then((response) => {
        if (this.props.isEditFlag) {
          this.ToastAlert(
            this.props.saveSettingsResponse.message == "Error"
              ? "warning"
              : "success",
            this.props.saveSettingsResponse.result
              ? this.props.saveSettingsResponse.result
              : "Settings Updated successfully"
          );
          this.props.handleCloseModal();
        } else {
          this.ToastAlert(
            this.props.saveSettingsResponse.message == "Error"
              ? "warning"
              : "success",
            this.props.saveSettingsResponse.result
              ? this.props.saveSettingsResponse.result
              : "Settings Saved successfully"
          );
          this.setState({
            selectedYear: "",
            selectedMonth: "",
            //years: [2024, 2025, 2026],
            year: "2024",
            // month: "0",
            startDate: "",
            endDate: "",
          });
          this.props.handleCloseModal();
        }
      });
    } catch (error) {
      this.ToastAlert("error", "Something went wrong");
    }
  };

  handleYearChange = (e) => {
    this.setState({
      selectedYear: e,
      startDate: "",
      endDate: "",
      year: e.label,
    });
  };

  handleMonthChange = (e) => {
    this.setState(
      {
        selectedMonth: e,
        batch: e.label,
        month: e.label == "April" ? "0" : "1",
        months: e.label == "April" ? ["March","April","May"] : ["September","October","November"],
        startDate:
          e.label == "April"
            ? new Date(this.state.year + "-04-01")
            : new Date(this.state.year + "-10-01"),
        endDate:
          e.label == "April"
            ? new Date(this.state.year + "-04-30")
            : new Date(this.state.year + "-10-31"),
        isStartDate: false,
        isEndDate: false,
      },
      () => {
      }
    );
  };

  // onClick show view and update modal
  viewUpdateModal = () => {
    return (
      <>
        <Modal
          className="add-setting-popup"
          show={this.props.settingsData.showSettingModal}
          onHide={() => this.props.handleCloseModal()}
        >
          <Modal.Header Confirm Delete closeButton>
            <Modal.Title className="custom-modal-title potential-heading">
              <h4 className="pull-left">Setting Form</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              {!this.props.isEditFlag && (
                <>
                  <>
                    <Form.Label>Year:</Form.Label>

                    <Select
                      className="basic-single-select mb-2"
                      classNamePrefix={"select_dropdown"}
                      placeholder="Select"
                      isSearchable={true}
                      onChange={(e) => this.handleYearChange(e)}
                      value={this.state.selectedYear}
                      options={this.getPerformanceDateYearOption()}
                      defaultValue={this.state.selectedYear}
                      disabled={false}
                    />
                  </>
                  <>
                    <Form.Label className="ps-0">Batch:</Form.Label>

                    <Select
                      className="basic-single-select mb-2"
                      classNamePrefix={"select_dropdown"}
                      placeholder="Select"
                      isSearchable={true}
                      onChange={(e) => this.handleMonthChange(e)}
                      value={this.state.selectedMonth}
                      options={this.state.monthsList}
                      defaultValue={this.state.selectedMonth}
                      disabled={false}
                    />
                  </>
                </>
              )}
              <>
                <div className="row align-items-center mb-2 date_picker_align ms-0">
                  <Form.Label className="ps-0">Start Date:</Form.Label>
                  <DatePicker
                    renderCustomHeader={({ date, changeYear, changeMonth }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <select
                          value={this.state.year}
                          onChange={({ target: { value } }) => {
                            this.setState({ year: value });
                            changeYear(value);
                          }}
                        >
                          {this.getPerformanceDateYearOption().map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>

                          <select
                            value={this.state.months[this.state.month]}
                            selected={this.state.month}
                            onChange={({ target: { value } }) => {
                              this.setState({ month: value });
                              changeMonth(this.state.allMonths.indexOf(value));
                            }}
                          >
                          {this.state.months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    placeholderText="Start Date"
                    selected={this.state.startDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      this.setStartEndDate(date, "start_date");
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    disabled={
                      this.props.isEditFlag ? false : this.state.isStartDate
                    }
                  />
                  <span class="cal_icon_date" role="button" tabindex="0" style={{ top: "40px" }}> <i class="icon-calendar"></i></span>
                  {this.state.startDateError && (
                    <div className="text-danger">
                      {this.state.startDateError}
                    </div>
                  )}
                </div>
              </>
              <>
                <div className="row align-items-center mb-2 date_picker_align ms-0">
                  <Form.Label className="ps-0" >End Date:</Form.Label>
                  <DatePicker
                    renderCustomHeader={({ date, changeYear, changeMonth }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <select
                          value={this.state.year}
                          onChange={({ target: { value } }) => {
                            this.setState({ year: value });
                            changeYear(value);
                          }}
                        >
                          {this.getPerformanceDateYearOption().map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>

                        <select
                          value={this.state.months[this.state.month]}
                          onChange={({ target: { value } }) => {
                            this.setState({ month: value });
                            changeMonth(this.state.allMonths.indexOf(value));
                          }}
                        >
                          {this.state.months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    selected={this.state.endDate}
                    placeholderText="End Date"
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      this.setStartEndDate(date, "end_date");
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    disabled={
                      this.props.isEditFlag ? false : this.state.isEndDate
                    }
                  />
                   <span class="cal_icon_date" role="button" tabindex="0" style={{ top: "40px" }}> <i class="icon-calendar"></i></span>
                  {this.state.endDateError && (
                    <div className="text-danger">{this.state.endDateError}</div>
                  )}
                </div>
              </>
              {/* <>
                <Form.Label>Batch:</Form.Label>

                <Form.Control
                  type="text"
                  name="projectName"
                  placeholder="Batch"
                  defaultValue={this.props.isEditFlag ? this.props.settingsData.batch : this.state.batch}
                  disabled
                />
              </> */}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="delete-modal">
            <Button
              className="btn  back_button cancel_back_btn"
              onClick={() => this.props.handleCloseModal()}
            >
              CANCEL
            </Button>
            <Button className="me-2" onClick={(e) => this.saveSettings(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  render() {
    return (
      <>
        {(this.state.showSettingModal ||
          this.props.settingsData.showSettingModal) &&
          this.viewUpdateModal()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { saveSettingsResponse: state.pmsDetails.saveSettingsResponse };
};

const mapDispatchToProps = { saveSettings };

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateSettings);
