import * as constants from "../Config/Constant";
import { postData } from "../Services/Helper";
import { handleResponse } from "../Services/HandleResponse";
import * as types from "../Utilities/Type";
import { getBackEndConstant } from "../Config/Constant";

export function getPmsDashboard(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "pms_dashboard";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.PMS_DASHBOARD,
          payload: response.result ? response.result : {},
        });
      });
  };
}

export function getChapterLead(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "get_chapter_lead";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.CHAPTER_LEADER,
          payload: response.result ? response.result : {},
        });
      });
  };
}
/**
 * Save the question
 * @param {*} dataObj
 * @returns
 */

export function saveQuestion(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "save_question";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.SAVE_QUESTION,
          payload: response.result ? response.result : {},
        });
      });
  };
}

/**
 * Get Pms question for grid
 * @param {*} dataObj
 * @returns
 */
export function getPmsQuestion(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "get_pms_question";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.GET_QUESTION,
          payload: response.result ? response.result : {},
        });
      });
  };
}

/**
 * Get Pms question for grid
 * @param {*} dataObj
 * @returns
 */
export function deletePmsQuestion(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "delete_pms_question";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.DELETE_QUESTION,
          payload: response.result ? response.result : {},
        });
      });
  };
}

/**
 * Get Pms question for grid
 * @param {*} dataObj
 * @returns
 */
export function editPmsQuestion(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "edit_pms_question";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.EDIT_QUESTION,
          payload: response.result ? response.result : {},
        });
      });
  };
}

/**
 * Save answer from user side
 * @param {*} dataObj
 * @returns
 */
export function saveAnswer(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "save_answer";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.SAVE_ANSWER,
          payload: response.result ? response.result : {},
        });
      });
  };
}

/**
 * get answer from user side
 * @param {*} dataObj
 * @returns
 */
export function getAnswer(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "get_answer";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.GET_ANSWER,
          payload: response.result ? response.result : {},
        });
      });
  };
}

/**
 * get answer from user side
 * @param {*} dataObj
 * @returns
 */
export function setAddClass(flag) {
  return (dispatch) => {
    dispatch({
      type: types.ADD_CLASS,
      payload: flag,
    });
  };
}

/**
 * get answer from user side
 * @param {*} dataObj
 * @returns
 */
export function getLevel(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "get_level";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.GET_LEVEL,
          payload: response.result ? response.result : {},
        });
      });
  };
}

/**
 * add comment for review
 * @param {*} dataObj
 * @returns
 */
export function addComment(dataObj) {
  return () => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "pms_add_comment";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        return response;
      });
  };
}

/**
 * download team statistics
 */
export function downloadStatistics(dataObj) {
  return () => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "download_statistics";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        return response.result;
      });
  };
}
export function getStartEndDate(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "get_start_end_date";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.GET_DATE,
          payload: response.result ? response.result : {},
        });
      });
  };
}

export function getDates(data) {
  return (dispatch) => {
    const requestOptions = postData(data);
    let url = getBackEndConstant().backend_url + "get_dates";

    return fetch(url, requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        dispatch({
          type: types.GET_DATES,
          payload: response.result ? response.result : {},
        });
      });
  };
}

export function saveSettings(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "save_settings";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.SAVE_SETTINGS,
          payload: response ? response : {},
        });
      });
  };
}

export function downloadPmsReport(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "download_pms_report";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.DOWNLOAD_PMS_REPORT,
          payload: response ? response : {},
        });
      });
  };
}

export function updateStatus(dataObj) {
  return () => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "update_status";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        return response;
      });
  };
}

export function downloadZip(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "download_zip";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.DOWNLOAD_ZIP,
          payload: response ? response : {},
        });
      });
  };
}
export function pmsExportExcel(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url  + "pms_user_excel";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.PMS_EXPORT_EXCEL,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}