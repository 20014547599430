export const GET_UTILIZATION = "getUtilizationReport";
export const GET_LOGIN_USER = "getLoginUser";
export const GET_REPORT_FILTER = "getReportFilter";
export const GET_LOGIN_USER_PERMISSION = "getLoginUserPermission";
export const UTLIZATION_EXPORT_EXCEL = "utilizationExportExcel";
export const GET_CSM_REPORT = "getCsmReport";
export const CSM_EXPORT_EXCEL = "csmExportExcel";
export const GET_CSM_SUMMARY_REPORT = "getCsmSummaryReport";
export const GET_CHECK_SUM = "getCheckSum";
export const SEND_CSM_EMAIL = "sendCsmEmail";
export const CSM_APPROVE_STATUS = "saveCsmApprovalStatus";
export const GET_PMO_REPORT = "getPmoReportData";
export const PMO_EXPORT_EXCEL = "pmoExportExcel";
export const GET_PMO_FILTER = "getReportFilterPmo";
export const GET_ACV_REPORT = "getAcvReportData";
export const ACV_EXPORT_EXCEL = "acvExportExcel";
export const GET_MS_ALLOCATION_REPORT = "getMsAllocationReportData";
export const MS_ALLOCATION_EXPORT_EXCEL = "msAllocationExportExcel";
export const GET_MS_SUMMARY_REPORT = "getMsSummaryReport";
export const MS_POTENTIAL_PROJECT_NAME = "getCurrentMonthProjectName";
export const MS_POTENTIAL_PROJECT_DETAILS = "getCurrentProjectDetails";
export const MS_POTENTIAL_PROJECT_LIST = "getPotentialProjectList";
export const MS_DELETE_CURRENT_POTENTIAL_PROJECT = "deleteCurrentPotentialProject";
export const MS_POTENTIAL_EXPORT_EXCEL = "msPotentialExportExcel";
export const MS_SAVE_CURRENT_POTENTIAL_PROJECT = "saveCurrentPotentialProject";
export const EDIT_PROJECT_POTENTIAL = "editProjectPotential";
export const SAVE_TARGET_VALUE = "saveMsSummaryTargetValue";
export const PMS_DASHBOARD = "getPmsDashboard";
export const CHAPTER_LEADER = "getChapterLead";
export const SAVE_QUESTION = "saveQusetion";
export const GET_QUESTION = "getPmsQuestion";
export const DELETE_QUESTION = "deletePmsQuestion";
export const EDIT_QUESTION = "editPmsQuestion";
export const SAVE_ANSWER = "saveAnswer";
export const GET_ANSWER = "getAnswer";
export const ADD_CLASS = "addClass";
export const GET_USER_GRID = "getPmsUserStatus";
export const GET_LEVEL = "getLevel";
export const GET_DATE = "getStartEndDate";
export const GET_DATES = "getDates";
export const SAVE_SETTINGS = "saveSettings";
export const DOWNLOAD_PMS_REPORT = "downloadPmsReport";
export const DOWNLOAD_ZIP = "downloadZip";
export const SAVE_COMMENTS = "saveComments";
export const REPORT_LEAD = "reportLead";
export const ITERATION_COMMENTS = "iterationComments";
export const GET_NOTIFICATIONS = "getNotifications";
export const MARK_NOTIFICATION = "markNotification";
export const PMS_EXPORT_EXCEL = "pmsExportExcel";